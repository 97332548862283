import React from "react";

import Ready from "../ready/ready";
import TopBanner from "../top_banner/product_top_banner";
import FAQS from "../faqs/faqs";
import Tariffs from "../tariffs/tariffs-simpler";
import SecondaryHeader from "../secondary-header";
import { secondaryMenu } from "../../constants";
import { useTranslation } from 'react-i18next';

const Tarifs = () => {
  const {t} = useTranslation();
  const supportFAQS = [
    {
      title: "Why don't you count usage/ active users/ tests taken as opposed to licensed users?",
      description: "Your success is our success. We want you to sell as much as humanly possible- if you win first, we can also win. As such, we need our incentives to be aligned. We want you to build the best possible product. We firmly believe that the more active your users are… the more value they will derive from the product… the more likely they are to stick around, and to tell their friends. If we make it about usage, you are explicitly disincentivized  to build a great product that people use.",
    },
    {
      title: "Can I buy just one product or feature / Do I have to buy the whole product suite?",
      description: `${t('no_in_accordance_with_cnil')}`,
    },
    {
      title: "Can I build a Proof-of-Concept before I buy?",
      description: `${t('no_mereos_does_not_monitor_candidates')}`,
    },
    {
      title: "Is Learnosity for me?",
      description: `${t('subtle_movements_such_as_those_mentioned')}`,
    },
    {
      title: "What’s your renewal process?",
      description: `${t('no_the_mereos_algorithm_only_provides')}`,
    },
    {
      title: "What is a user?",
      description: `${t('no_the_mereos_algorithm_only_provides')}`,
    },
  ];

  return (
    <div className={`font-sans`}>
      <SecondaryHeader
        menu={secondaryMenu}
      />
      <TopBanner
        pageTitle={t('registration_fee')}
        title={t('registration_fees')}
        description={t('to_register_for_the_test')}
        imageSrc="https://d2lxkizvrhu4im.cloudfront.net/images/person-rising.svg"
        page={"platform"}
      />

      <div className={`mx-auto w-11/12 lg:w-5/6 my-20`}>
        <Tariffs
          tableName={`${t('amount_of_registration_fees')}`}
        />
        <div className="mx-auto my-20">
          <FAQS
            title={t('the_most_frequently_asked_questions_about_our_data')}
            faqsType='Support FAQs'
            data={supportFAQS}
          />
        </div>
        <div className="mx-auto my-20">
          <Ready
            title={t('ready_to_test_mereos')}
            description={t('discover_our_ready_to_use_solutions')}
            leftButton={{
              text: `${t('register')}`,
              href: "https://registration.mereos.eu/candidature/?id=1"
            }}
            rightButton={{
              text: t('learn_more'),
              href: ""
            }}
            image="https://d2lxkizvrhu4im.cloudfront.net/images/Market_launch.svg"
          />
        </div>
      </div>
    </div>
  );
};

export default Tarifs;
