import * as React from "react";

import Layout from "../../../components/layout/layout";
import Seo from "../../../components/seo";
import Tarifs from "../../../components/tasem-test/tarifs";

const Index = () => {
  return (
    <>
      <Seo
        title="Tarifs relatifs au TASEM"
        description="Pour s’inscrire au test TASEM et être en mesure de passer les épreuves en ligne, les candidats doivent s’acquitter de frais d’inscription au test. Ces tarifs sont susceptibles d’évoluer en cours d’année."
      />
      <Layout>
        <Tarifs />  
      </Layout>
    </>
  )
}

export default Index;
