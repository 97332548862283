export const FORM_STEPS = ["Renseignements", "Date & Heure", "Fin"];
export const STEPPER_IMAGES = [
  "https://d2lxkizvrhu4im.cloudfront.net/icons/active_stepper_icon.svg",
  "https://d2lxkizvrhu4im.cloudfront.net/icons/checked_stepper_icon.svg",
  "https://d2lxkizvrhu4im.cloudfront.net/icons/idol_stepper_icon.svg",
];

export const toggler= [{name:'Lockdown',iconSrc:"bulb_on_icon.svg", state: true}, {name:'Web-App',iconSrc:"broadcast_icon.svg", state: false},{name:'Extension',iconSrc:"broadcast_icon.svg", state: false}]

export const priceSlider=[{name:'standard',iconSrc:"bulb_on_icon.svg", state: true}, {name:'Scale',iconSrc:"broadcast_icon.svg", state: false},  {name:'Enterprise',iconSrc:"broadcast_icon.svg", state: false}]



// products

// product lockdown component data
export const ContenuLockdownFunctionData = [
  {
    iconSrc:"Force Full Screen.svg",
    title:"Forcer le plein écran",
    description:"L’évaluation s’ouvre et se déroule en mode plein écran. Le candidat ne pourra pas quitter sa session pour consulter des documents externes ou un autre site internet."
  },
  {iconSrc:"Only One Screen.svg",title:"Autre écran détecté", description:"Le candidat est contraint de débrancher tout autre écran pour accéder à son évaluation. Cela permet d’empêcher la diffusion du contenu du test sur un moniteur externe."},
  {iconSrc:"Disable New Tabs.svg",title:"Nouvel onglet inaccessible", description:"Le système empêche le candidat d’ouvrir un nouvel onglet pendant son évaluation afin qu’il ne puisse pas chercher des réponses à ses questions ou communiquer avec un tiers."},
  {iconSrc:"Close Open Tabs.svg",title:"Navigateur nettoyé en amont", description:"Avant que le test ne démarre, le système ferme tous les onglets ouverts sur le navigateur du candidat afin qu’il ne puisse pas conserver un support de cours en arrière-plan par exemple"},
  {iconSrc:"Disable Printing.svg",title:"Cache nettoyé en aval", description:"Toutes les traces de l’évaluation seront automatiquement supprimées de l'ordinateur du candidat une fois la session terminée."},
  {iconSrc:"Disable Clipboard.svg",title:"Imprimante désactivée", description:"L’impression et le téléchargement ne sont pas autorisés lors de la session d’évaluation. On s’assure ainsi que le contenu des tests ne sera pas diffusé en ligne ou sur papier."},
  {iconSrc:"Clear Cache.svg",title:"Extension désactivée", description:"L’utilisation d’autres extensions n’est pas autorisée lors de la session du candidat. On s’assure ainsi que le contenu des tests ne sera pas diffusé et que le candidat ne pourra pas communiquer avec un tiers."},
  {iconSrc:"Disable Right Click.svg",title:"Clic-droit désactivé", description:"En désactivant le clic-droit, le système empêche les candidats d'utiliser la fonction copier-coller pendant toute la durée du test."},
  {
    iconSrc:"Prevent Re-Entry.svg",
    title:"Raccourcis-clavier désactivés",
    description:"En désactivant les raccourcis-clavier, le système empêche les candidats de sortir de leur session ou de récupérer du contenu de quelque manière que ce soit."
  }
];

export const secondaryMenu = [
  {name: `the_tasem`, href: '/tests/tasem-test'},
  {name: 'registration', href: '/tests/tasem-test/inscription'},
  {name: 'arrangement', href: '/tests/tasem-test/acces'},
  {name: 'Score', href: '/tests/tasem-test/score'},
  {name: 'rate', href: '/tests/tasem-test/tarifs'},
  // {name: 'prepration', href: '/tests/tasem-test/preparation'},
  {name: 'register', href: 'https://registration.mereos.eu/candidature/?id=1'},
]
