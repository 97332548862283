import React from "react";
import { useTranslation } from 'react-i18next';

const SecondaryHeader = ({menu}) => {
    const {t} = useTranslation();
    return (
        <nav className="bg-black dark:bg-white">
            <div className="max-w-screen-xl px-4 py-3 mx-auto">
                <div className="flex items-center">
                    <ul className="flex flex-row font-medium mr-6 space-x-8 text-sm my-auto">
                        {
                            menu.map((item, index, menu) => (
                                <li
                                    style={{marginBottom: '0px'}}
                                >
                                    <a
                                        href={item.href}
                                        className={`text-white dark:text-black hover:underline ${index === menu.length - 1 ? 'border border-yellow-300 px-8 py-2 rounded-md' : ''}`}
                                    >
                                        {t(item.name)}
                                    </a>
                                </li>
                            )) 
                        }
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default SecondaryHeader;
