import React from "react";

import classes from "./tariffs.module.scss";
import { useTranslation } from 'react-i18next';

const Tariffs = ({tableCategory, tableName, tableDescription}) => {
    const {t} = useTranslation();
    return (
        <div className="space-y-6 md:space-y-10">
            {
                tableCategory && 
                <div className={`text-xs md:text-base font-semibold opacity-60 text-center`}>
                    {tableCategory}
                </div>
            }
            {
                tableName &&
                <div className={`text-3xl md:text-6xl text-center font-bold  w-10/12 lg:w-4/5 mx-auto`}>
                    {tableName}
                </div>
            }

            {
                tableDescription && 
                <div className={`my-5 font-normal text-sm lg:text-xl text-gray-500 w-5/6 md:w-full mx-auto text-center`}>
                    {tableDescription}
                </div>
            }
            <table className="w-full text-sm text-left text-gray-500 border-b">
                <tbody>
                    {/* <tr valign="top" className="border-2 bg-white dark:bg-yellow-100 dark:border-yellow-300">
                        <th className={`p-4 font-medium text-gray-900 dark:text-white`}>
                            <div className="font-semibold">{t('holder_of_french_diploma')}</div>
                            <div className="text-gray-400">{t('diploma_in_3_years')} </div>
                        </th>
                        <th className={`p-4 w-1/2 font-medium text-gray-900 dark:text-white pl-2`}>
                            <div className="font-semibold">{t('the_following_items_are_included')}</div>
                            <ul style={{listStyleImage: "url('https://d2lxkizvrhu4im.cloudfront.net/icons/tickmark-orange-round.svg')"}}>
                                <li className='p-1 m-0'>{t('registration_for_unlimited_number')} </li>
                                <li className='p-1 m-0'>{t('registration_for_unlimited_programs')} </li>
                                <li className='p-1 m-0'>{t('mereos_assistance_in_french')} </li>
                                <li className='p-1 m-0'>{t('the_delivery_of_digital')} </li>
                            </ul>
                        </th>
                        <th className={`p-4 font-medium text-gray-900 dark:text-white pl-2 bg-yellow-50`}>
                            <div className="font-semibold">132€</div>
                            <div>{t('all_taxes_included')} </div>
                        </th>
                    </tr> */}
                    <tr valign="top" className="border-2 bg-white dark:bg-yellow-100 dark:border-yellow-300">
                        <th className={`p-4 font-medium text-gray-900 dark:text-white`}>
                            <div className="font-semibold">{t('holder_of_non_French_diploma')}</div>
                            <div className="text-gray-400">{t('diploma_in_3_years_or_more_delivered')}</div>
                        </th>
                        <th className={`p-4 w-1/2 font-medium text-gray-900 dark:text-white pl-2`}>
                            <div className="font-normal">
                                {t('the_same_elements_are_included')}
                            </div>
                        </th>
                        <th className={`p-4 font-medium text-gray-900 dark:text-white pl-2 bg-yellow-50`}>
                            <div className="font-semibold">132€</div>
                            <div>{t('all_taxes_included')}</div>
                        </th>
                    </tr>
                </tbody>
            </table>
          
        </div>
    )
}

export default Tariffs;
